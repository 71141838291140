.Toastify__toast-container {
  z-index: 9999;
  position: fixed;
  padding: 4px;
  width: 320px;
  box-sizing: border-box;
  color: #fff; }
  .Toastify__toast-container--top-left {
    top: 1em;
    left: 1em; }
  .Toastify__toast-container--top-center {
    top: 1em;
    left: 50%;
    margin-left: -160px; }
  .Toastify__toast-container--top-right {
    top: 1em;
    right: 1em; }
  .Toastify__toast-container--bottom-left {
    bottom: 1em;
    left: 1em; }
  .Toastify__toast-container--bottom-center {
    bottom: 1em;
    left: 50%;
    margin-left: -160px; }
  .Toastify__toast-container--bottom-right {
    bottom: 1em;
    right: 1em; }

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0; }
    .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
      top: 0; }
    .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
      bottom: 0; }
    .Toastify__toast-container--rtl {
      right: 0;
      left: initial; } }

.Toastify__toast {
  position: relative;
  min-height: 64px;
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 1px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  max-height: 800px;
  overflow: hidden;
  font-family: sans-serif;
  cursor: pointer;
  direction: ltr; }
  .Toastify__toast--rtl {
    direction: rtl; }
  .Toastify__toast--default {
    background: #fff;
    color: #aaa; }
  .Toastify__toast--info {
    background: #3498db; }
  .Toastify__toast--success {
    background: #07bc0c; }
  .Toastify__toast--warning {
    background: #f1c40f; }
  .Toastify__toast--error {
    background: #e74c3c; }
  .Toastify__toast-body {
    margin: auto 0;
    flex: 1 1; }

@media only screen and (max-width: 480px) {
  .Toastify__toast {
    margin-bottom: 0; } }

.Toastify__close-button {
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  align-self: flex-start; }
  .Toastify__close-button--default {
    color: #000;
    opacity: 0.3; }
  .Toastify__close-button:hover, .Toastify__close-button:focus {
    opacity: 1; }

@-webkit-keyframes Toastify__trackProgress {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); }
  100% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0); } }

@keyframes Toastify__trackProgress {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); }
  100% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0); } }

.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: 9999;
  opacity: 0.7;
  background-color: rgba(255, 255, 255, 0.7);
  -webkit-transform-origin: left;
          transform-origin: left; }
  .Toastify__progress-bar--animated {
    -webkit-animation: Toastify__trackProgress linear 1 forwards;
            animation: Toastify__trackProgress linear 1 forwards; }
  .Toastify__progress-bar--controlled {
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s; }
  .Toastify__progress-bar--rtl {
    right: 0;
    left: initial;
    -webkit-transform-origin: right;
            transform-origin: right; }
  .Toastify__progress-bar--default {
    background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55); }

@-webkit-keyframes Toastify__bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes Toastify__bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
            transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
            transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
            transform: translate3d(-5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@-webkit-keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
            transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); } }

@-webkit-keyframes Toastify__bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes Toastify__bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
            transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
            transform: translate3d(5px, 0, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@-webkit-keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
            transform: translate3d(20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
            transform: translate3d(-2000px, 0, 0); } }

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
            transform: translate3d(20px, 0, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
            transform: translate3d(-2000px, 0, 0); } }

@-webkit-keyframes Toastify__bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes Toastify__bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
            transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@-webkit-keyframes Toastify__bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

@keyframes Toastify__bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

@-webkit-keyframes Toastify__bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
            transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
            transform: translate3d(0, 25px, 0); }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@keyframes Toastify__bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
            transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
            transform: translate3d(0, 25px, 0); }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0); }
  to {
    -webkit-transform: none;
            transform: none; } }

@-webkit-keyframes Toastify__bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
            transform: translate3d(0, 2000px, 0); } }

@keyframes Toastify__bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0); }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
            transform: translate3d(0, 2000px, 0); } }

.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  -webkit-animation-name: Toastify__bounceInLeft;
          animation-name: Toastify__bounceInLeft; }

.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  -webkit-animation-name: Toastify__bounceInRight;
          animation-name: Toastify__bounceInRight; }

.Toastify__bounce-enter--top-center {
  -webkit-animation-name: Toastify__bounceInDown;
          animation-name: Toastify__bounceInDown; }

.Toastify__bounce-enter--bottom-center {
  -webkit-animation-name: Toastify__bounceInUp;
          animation-name: Toastify__bounceInUp; }

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  -webkit-animation-name: Toastify__bounceOutLeft;
          animation-name: Toastify__bounceOutLeft; }

.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  -webkit-animation-name: Toastify__bounceOutRight;
          animation-name: Toastify__bounceOutRight; }

.Toastify__bounce-exit--top-center {
  -webkit-animation-name: Toastify__bounceOutUp;
          animation-name: Toastify__bounceOutUp; }

.Toastify__bounce-exit--bottom-center {
  -webkit-animation-name: Toastify__bounceOutDown;
          animation-name: Toastify__bounceOutDown; }

@-webkit-keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@-webkit-keyframes Toastify__zoomOut {
  from {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 0; } }

@keyframes Toastify__zoomOut {
  from {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 0; } }

.Toastify__zoom-enter {
  -webkit-animation-name: Toastify__zoomIn;
          animation-name: Toastify__zoomIn; }

.Toastify__zoom-exit {
  -webkit-animation-name: Toastify__zoomOut;
          animation-name: Toastify__zoomOut; }

@-webkit-keyframes Toastify__flipIn {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  to {
    -webkit-transform: perspective(400px);
            transform: perspective(400px); } }

@keyframes Toastify__flipIn {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  to {
    -webkit-transform: perspective(400px);
            transform: perspective(400px); } }

@-webkit-keyframes Toastify__flipOut {
  from {
    -webkit-transform: perspective(400px);
            transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

@keyframes Toastify__flipOut {
  from {
    -webkit-transform: perspective(400px);
            transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

.Toastify__flip-enter {
  -webkit-animation-name: Toastify__flipIn;
          animation-name: Toastify__flipIn; }

.Toastify__flip-exit {
  -webkit-animation-name: Toastify__flipOut;
          animation-name: Toastify__flipOut; }

@-webkit-keyframes Toastify__slideInRight {
  from {
    -webkit-transform: translate3d(110%, 0, 0);
            transform: translate3d(110%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideInRight {
  from {
    -webkit-transform: translate3d(110%, 0, 0);
            transform: translate3d(110%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@-webkit-keyframes Toastify__slideInLeft {
  from {
    -webkit-transform: translate3d(-110%, 0, 0);
            transform: translate3d(-110%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideInLeft {
  from {
    -webkit-transform: translate3d(-110%, 0, 0);
            transform: translate3d(-110%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@-webkit-keyframes Toastify__slideInUp {
  from {
    -webkit-transform: translate3d(0, 110%, 0);
            transform: translate3d(0, 110%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideInUp {
  from {
    -webkit-transform: translate3d(0, 110%, 0);
            transform: translate3d(0, 110%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@-webkit-keyframes Toastify__slideInDown {
  from {
    -webkit-transform: translate3d(0, -110%, 0);
            transform: translate3d(0, -110%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes Toastify__slideInDown {
  from {
    -webkit-transform: translate3d(0, -110%, 0);
            transform: translate3d(0, -110%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@-webkit-keyframes Toastify__slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(110%, 0, 0);
            transform: translate3d(110%, 0, 0); } }

@keyframes Toastify__slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(110%, 0, 0);
            transform: translate3d(110%, 0, 0); } }

@-webkit-keyframes Toastify__slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-110%, 0, 0);
            transform: translate3d(-110%, 0, 0); } }

@keyframes Toastify__slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-110%, 0, 0);
            transform: translate3d(-110%, 0, 0); } }

@-webkit-keyframes Toastify__slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 500px, 0);
            transform: translate3d(0, 500px, 0); } }

@keyframes Toastify__slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 500px, 0);
            transform: translate3d(0, 500px, 0); } }

@-webkit-keyframes Toastify__slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -500px, 0);
            transform: translate3d(0, -500px, 0); } }

@keyframes Toastify__slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -500px, 0);
            transform: translate3d(0, -500px, 0); } }

.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  -webkit-animation-name: Toastify__slideInLeft;
          animation-name: Toastify__slideInLeft; }

.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  -webkit-animation-name: Toastify__slideInRight;
          animation-name: Toastify__slideInRight; }

.Toastify__slide-enter--top-center {
  -webkit-animation-name: Toastify__slideInDown;
          animation-name: Toastify__slideInDown; }

.Toastify__slide-enter--bottom-center {
  -webkit-animation-name: Toastify__slideInUp;
          animation-name: Toastify__slideInUp; }

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  -webkit-animation-name: Toastify__slideOutLeft;
          animation-name: Toastify__slideOutLeft; }

.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  -webkit-animation-name: Toastify__slideOutRight;
          animation-name: Toastify__slideOutRight; }

.Toastify__slide-exit--top-center {
  -webkit-animation-name: Toastify__slideOutUp;
          animation-name: Toastify__slideOutUp; }

.Toastify__slide-exit--bottom-center {
  -webkit-animation-name: Toastify__slideOutDown;
          animation-name: Toastify__slideOutDown; }

/*# sourceMappingURL=ReactToastify.css.map */
body.react-confirm-alert-body-element {
  overflow: hidden;
}

.react-confirm-alert-blur {
  -webkit-filter: url(#gaussian-blur);
          filter: url(#gaussian-blur);
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(255, 255, 255, 0.9);
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-body {
  font-family: Arial, Helvetica, sans-serif;
  width: 400px;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
}

.react-confirm-alert-svg {
  position: absolute;
  top: 0;
  left: 0;
}

.react-confirm-alert-body > h1 {
  margin-top: 0;
}

.react-confirm-alert-body > h3 {
  margin: 0;
  font-size: 16px;
}

.react-confirm-alert-button-group {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.react-confirm-alert-button-group > button {
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

@-webkit-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

